import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PubSub from 'pubsub-js';
import { browserHistory } from 'react-router';
import {
  LongTextFieldTitle,
  TextArea,
  DatePickerForm,
  TimerForm,
  ToggleForm,
  ToogleNoReduxForm,
  AutoCompleteForm
} from '../utils_form/FormComponents';
import { DropDownDefaultOnlyOne } from '../utils/FormsComponents';
import ButtonDefault from '../utils/FormsComponents';
import { required } from '../validation/FormValidation';
import { MuiThemeProvider } from 'material-ui/styles';
import { formValueSelector } from 'redux-form';
import TagApi from '../../Logicas/TagApi';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import ArrowLeft from '../utils/img/ic-seta-anterior.png';
import ArrowRight from '../utils/img/ic-seta-proximo.png';
import HeaderCalendar from '../headers/HeaderCalendar/HeaderCreate';
import UserApi from '../../Logicas/UserApi';
import CalendarApi from '../../Logicas/CalendarApi';
import EventRow from './EventRow';
import Zoom from 'react-reveal/Zoom';
import DialogApi from '../../Logicas/DialogApi';
import { DialogYesNo } from '../utils/DailogComponentes';
import { style } from '../../style';
import { BodyPreviewEvent } from './style_calendar';
import Modal from 'react-awesome-modal';
import SelectTags from './Components/SelectTags/';
import SelectedTagsList from './Components/SelectedTagsList';
const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  timePicker: {
    pickerHeaderColor: style.inpuStyle.colorInput,
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput,
    pickerSelectColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});
require('./neweventform.css');
class NewEventForm extends Component {
  constructor() {
    super();
    this.state = {
      isHoliday: false,
      isOnline: false,
      notifyUsers: true,
      when_notify: 'Ao criar o evento'
    };
    this.getDateCallBack = this.getDateCallBack.bind(this);
  }

  componentWillMount() {
    const {
      eventEdit: { link: eventLink },
      tags_selected,
      getUserTagsAssociation
    } = this.props;

    if (eventLink) {
      this.setState({ isOnline: true });
    }

    getUserTagsAssociation(null, tags_selected);
    PubSub.subscribe(
      'render-calendar',
      function(render) {
        this.forceUpdate();
      }.bind(this)
    );

    if (this.props.eventEdit.event_id && this.props.eventEdit.event_id !== 0) {
      this.updateSelectedTags(this.props.tags_selected);
    }
  }

  getDateCallBack(value, date, text) {
    this.props.getEvents(date);
  }

  updateSelectedTags = (selectedTags) => {
    let selectedTagsParsed = [];
    Object.keys(selectedTags).forEach((item) => {
      let tagWithId = this.props.tags_with_ids.find(
        (tag) => tag.name === selectedTags[item]['value']
      );
      tagWithId !== undefined && selectedTagsParsed.push(tagWithId);
    });
    this.props.getUserTagsAssociation(null, selectedTagsParsed);
    this.props.updateSelectedTags(Object.values(selectedTagsParsed));
  };

  cancelPatch = () => {
    browserHistory.push('/calendar');
    this.props.getTodayEventsDate.bind();
  };

  patchEvent = (values) => {
    const { tags_selected, patchEvent } = this.props;
    const { isOnline } = this.state;
    values.event_tags = tags_selected;
    patchEvent(values, isOnline);
  };

  saveEvent = (values) => {
    const { tags_selected, saveEvent } = this.props;
    const { isOnline, notifyUsers, when_notify } = this.state;
    values.event_tags = tags_selected;
    saveEvent(values, isOnline, notifyUsers, when_notify);
  };

  render() {
    const { handleSubmit } = this.props;
    const { isOnline, notifyUsers } = this.state;

    let roles = JSON.parse(localStorage.getItem('UserRoles'));
    let roleAdmin = roles.filter(function(role) {
      return role.name === 'admin';
    });
    let roleManager = roles.filter(function(role) {
      return role.name === 'manager';
    });
    const tagspeaker = JSON.parse(localStorage.getItem('tagspeaker'));
    const tags = tagspeaker.map((tag) => tag.name);

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <HeaderCalendar
          cancel={this.cancelPatch}
          name={
            this.props.eventEdit.event_id && this.props.eventEdit.event_id !== 0
              ? 'Editar evento'
              : 'Criar novo evento'
          }
          onClick={
            this.props.eventEdit.event_id && this.props.eventEdit.event_id !== 0
              ? handleSubmit((values) => this.patchEvent({ ...values }))
              : handleSubmit((values) => this.saveEvent({ ...values }))
          }
        />
        <div className="create-event">
          <form
            className={
              this.props.valid
                ? 'body-calendar-form-valid'
                : 'body-calendar-form'
            }
          >
            <div className="body-form-title">
              <LongTextFieldTitle
                name="event_name"
                label="Nome do evento"
                validations={[required]}
              />
              <div className="input-holiday">
                <ToggleForm
                  name="holiday"
                  label="É feriado"
                  value="holiday"
                  onChange={() =>
                    this.setState({ isHoliday: !this.state.isHoliday })
                  }
                />
              </div>
            </div>
            <div className="body-form-title">
              <TextArea
                name="event_description"
                rows={3}
                labelsecondari="Digite uma descriçao"
                validations={[required]}
                id="name"
                label="Descriçao do evento*"
              />
            </div>
            <div className="body-form-envet-date">
              <DatePickerForm
                validations={[required]}
                name="event_date"
                label="Data*"
              />
              {!this.state.isHoliday && (
                <>
                  <TimerForm
                    floatingLabelFixed={true}
                    validations={[required]}
                    type="time"
                    name="event_hour_start"
                    floatingLabelText="Início*"
                  />
                  <TimerForm
                    floatingLabelFixed={true}
                    validations={[required]}
                    type="time"
                    name="event_hour_finish"
                    floatingLabelText="Término*"
                  />
                </>
              )}
            </div>
            <div className="row">
              <div className="col-md-4">
                <ToogleNoReduxForm
                  onChange={() => this.setState({ isOnline: !isOnline })}
                  value={isOnline}
                  label="É um evento online"
                />
              </div>
              {this.props.eventEdit.event_id &&
              this.props.eventEdit.event_id !== 0 ? (
                <></>
              ) : (
                <div className="col-md-4">
                  <ToogleNoReduxForm
                    onChange={() =>
                      this.setState({ notifyUsers: !notifyUsers })
                    }
                    value={notifyUsers}
                    label="Notificar usuários"
                  />
                </div>
              )}
            </div>
            {!(
              this.props.eventEdit.event_id &&
              this.props.eventEdit.event_id !== 0
            ) &&
              notifyUsers && (
                <div className="row">
                  <div className="col-md-4">
                    <DropDownDefaultOnlyOne
                      multiple={false}
                      name="when_notify"
                      required={true}
                      value={this.state.when_notify}
                      onChange={(event, index, searchValue) =>
                        this.setState({
                          when_notify: searchValue
                        })
                      }
                      array={[
                        'Ao criar o evento',
                        'Uma semana antes',
                        'Uma hora antes',
                        'Um dia antes'
                      ]}
                      floatingLabelText={'Definir notificação'}
                    />
                  </div>
                </div>
              )}
            {!isOnline ? (
              <div className="body-form-title">
                <TextArea
                  validations={[required]}
                  name="event_local"
                  rows={3}
                  label="Local"
                  labelsecondari="Onde acontecerá o evento"
                />
              </div>
            ) : (
              <TextArea
                validations={[required]}
                name="link"
                rows={1}
                label="Link"
                labelsecondari="Link para o evento online"
              />
            )}
            {/* TODO: Resolve this screen after expressionsTags implementation on API */}
            {/* {this.props.event_edit.event_id && this.props.event_edit.event_id !== 0 && this.props.initialValues ? ( */}
            <div className="body-form-tag-row">
              <AutoCompleteForm
                label="Defina as tags dos convidados"
                tags={
                  roleAdmin[0] || roleManager[0] ? this.props.listTagName : tags
                }
                name="event_tags"
                validations={[required]}
                isLoading={this.props.isLoadingTags}
                onChange={this.updateSelectedTags}
                getTagAutoComplete={() => {}}
                infiniteScroll={
                  roleAdmin[0] || roleManager[0] ?
                  {
                    searchByRequest: true,
                    loadMore: (page, search) =>
                      this.props.getTags(page, search, false, true, page > 1)
                  }:{
                    searchByRequest: false,
                    loadMore: () => {}
                  }
                }
              />
              <label className="user-association-tags">
                {this.props.userTagAssociation} usuários convidados
              </label>
            </div>
            {/* TODO: Resolve this screen after expressionsTags implementation on API */}
            {/* ) : ( */}
            {/* <div className='form-group-input-row'>
                <ComponentSegmentationTags
                  onChange={(tagsSelected) =>
                    this.setState({ expressionTags: tagsSelected })
                  }
                  tags={this.props.tags}
                  showHeader={true}
                />
              </div> */}
            {/* )} */}
          </form>
          <BodyPreviewEvent>
            <div className="container">
              <div className="header-preview">
                <img
                  className="img-click"
                  onClick={this.props.getPreviousDay.bind(
                    this,
                    this.props.event_date_preview
                  )}
                  style={{ width: '9px', height: '18px' }}
                  src={ArrowLeft}
                  alt="dia anterior"
                />
                <div className="body-form-date">
                  <DatePickerForm
                    onChange={this.getDateCallBack}
                    name="event_date_preview"
                  />
                </div>
                <img
                  className="img-click"
                  onClick={this.props.getNextDay.bind(
                    this,
                    this.props.event_date_preview
                  )}
                  style={{ width: '9px', height: '18px' }}
                  src={ArrowRight}
                  alt="dia seguinte"
                />
              </div>
            </div>

            {Array.isArray(this.props.eventsList) ? (
              this.props.eventsList.length > 0 ? (
                <div
                  className={
                    this.props.eventsList.length > 2 ? 'body-event' : ''
                  }
                >
                  <div>
                    {this.props.eventsList.map((event) => {
                      return !!event.title ? (
                        <Zoom>
                          <EventRow
                            editEvent={this.props.editEvent}
                            deleteShowDialogEvent={
                              this.props.deleteShowDialogEvent
                            }
                            event={event}
                          />
                        </Zoom>
                      ) : (
                        <></>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="label-no-events">
                  {' '}
                  Nenhum evento programado.{' '}
                </div>
              )
            ) : (
              <div className="label-no-events"> Nenhum evento programado. </div>
            )}
          </BodyPreviewEvent>
        </div>
        {this.props.alert.visible ? (
          <DialogYesNo
            yesclick={this.props.deleteEvent.bind(
              this,
              this.props.alert.id_elemente
            )}
            noclick={this.props.closeDialog}
            onClick={this.props.closeDialog}
            visible={this.props.alert.visible}
            message="Tem certeza que deseja remover este evento ?"
          />
        ) : null}
      </MuiThemeProvider>
    );
  }
}

NewEventForm = reduxForm({ form: 'new_event_calendar' })(NewEventForm);
const selector = formValueSelector('new_event_calendar');
const mapStateToProps = (state) => {
  return {
    listTagName: state.tagsWithPagination.tags,
    isLoadingTags: state.tagsWithPagination.isLoading,
    tags_with_ids: state.tagsWithIds.tags,
    alert: state.dialog,
    initialValues: state.eventsReducer.eventEdit,
    tags_selected: state.eventsReducer.eventEdit.event_tags,
    event_date_preview: selector(state, 'event_date_preview'),
    event_date: selector(state, 'event_date'),
    userTagAssociation: state.user.userTagAssociation,
    eventEdit: state.eventsReducer.eventEdit,
    eventsList: state.eventsReducer.eventsList,
    showModal: state.show_modal_birthday
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTags: async (
      page,
      search,
      parseId = false,
      withCount = true,
      continuationWithIds = false
    ) => {
      const res = await dispatch(
        TagApi.getTagsUserV2(
          page,
          search,
          parseId,
          withCount,
          continuationWithIds
        )
      );
      return res;
    },
    deleteShowDialogEvent: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    getUserTagsAssociation: (event, tags) =>
      dispatch(UserApi.getUserTagsAssociation(tags)),
    updateSelectedTags: (selectedTags) =>
      dispatch(CalendarApi.updateSelectedTags(selectedTags)),
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    deleteEvent: (event_, id) => dispatch(CalendarApi.deleteEvent(event_)),
    editEvent: (event_, id) => dispatch(CalendarApi.getEvent(event_)),
    getNextDay: (event_, date) => {
      !!event_ &&
      dispatch(CalendarApi.getNextDay(event_))
    },
    getPreviousDay: (event_, date) =>
      dispatch(CalendarApi.getPreviousDay(event_)),
    getTodayEventsDate: (date, event) =>
      date !== undefined
        ? dispatch(CalendarApi.getTodayEvents(date))
        : () => undefined,
    getEvents: (date, event) => dispatch(CalendarApi.getEvents(date)),
    patchEvent: (values, isOnline) =>
      dispatch(CalendarApi.patchEvent(values, isOnline)),
    saveEvent: (values, isOnline, notifyUsers, when_notify) => {
      dispatch(
        CalendarApi.saveEvent(values, isOnline, notifyUsers, when_notify)
      );
    },
    getTodayEvents: () => {
      dispatch(CalendarApi.getTodayEvents(new Date()));
    },
    closeDialogEventConfirm: () => dispatch({ type: 'SHOW_MODAL_BIRTHDAY' })
  };
};
const LoginListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewEventForm);

export default LoginListContainer;
