import API, {
  API_RANDOM_UPLOAD_VIDEO,
  API_V2
} from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import PubSub from 'pubsub-js';
import Axios from 'axios';
import { style } from '../style';
import PostApi from './PostApi';
import imageTypeFilter from '../componentes/utils/imageTypeFilter';
import errorHandler from '../componentes/utils/errorHandler';
import roleAdmin from '../componentes/utils/roleAdmin';
import { doesContainHttp } from '../componentes/validation/FormValidation';
import { getVideoCover } from '../componentes/utils/getVideoCover';
import { addImageProcess } from '../componentes/utils/addImageProcess';
import { canAddFiles } from '../componentes/utils/canAddFiles';

const isClientCipa = style.teamName.name === 'Cipa';
const hasLargeImageLimit = ['youseed', 'sample', 'MRN', 'pelando'].includes(
  style.teamName.name
);

export const saveContentText = (id, values, edit) => async (dispatch) => {
  try {
    dispatch({
      type: 'SHOW_STATUS',
      enablesubmit: false
    });
    const CONFI = Config.headers();
    CONFI.onUploadProgress = (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      dispatch({
        type: 'SHOW_PROGRESS',
        alert: {
          visible: true,
          percentage: percentCompleted
        }
      });
    };
    const formData = new FormData();
    formData.append('text', values);
    formData.append('kind', 'text');

    const { data } = await API.post(
      'canvas_publications/' + id + '/canvas_contents',
      formData,
      CONFI
    );

    dispatch({
      type: 'formPost',
      kind: 'text',
      value: data
    });
    dispatch({
      type: 'SHOW_PROGRESS',
      alert: {
        visible: false,
        percentage: 0
      }
    });
    dispatch({
      type: 'NO_SUBMITTING'
    });
    dispatch({
      type: 'formPostEdit',
      kind: 'text',
      value: data,
      insertText: true,
      edittext: true
    });
    dispatch({
      type: 'HIDE_STATUS',
      enablesubmit: true
    });
    PubSub.publish('re-render-canvas', true);
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

const isValidImage = (values) => {
  let image = {
    isInvalid: false
  };
  values.forEach((value) => {
    image.name = value.name;
    image.isInvalid = !imageTypeFilter.includes(value.type);

    if (image.name.length > 30) {
      image.name = `${image.name.substring(0, 15)}(...)${image.name.slice(
        image.name.length - 15
      )}`;
    }
  });
  if (image.isInvalid) {
    return image;
  }
  return values;
};

function isGif(file) {
  return file.type === 'image/gif';
}

export const saveContentImage = (id, values) => async (dispatch) => {
  let response = false;
  try {
    const i = await addImageProcess(URL.createObjectURL(values[0]));

    if (isGif(values[0]) && values[0].size / (1024 * 1024).toFixed(2) > 20)
      throw new Error(
        'Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.'
      );
    if (
      (hasLargeImageLimit && (i.height > 4032 || i.width > 4032)) ||
      (!hasLargeImageLimit && (i.height > 2000 || i.width > 2000))
    )
      throw new Error('Não foi possível carregar a imagem');

    const invalidImage = await isValidImage(values);
    if (invalidImage.isInvalid) {
      dispatch({
        type: 'HIDE_STATUS',
        enablesubmit: true
      });
      dispatch({
        type: 'END_LOAD'
      });
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: `Imagem em formato não suportado: ${invalidImage.name}`
        }
      });
      return;
    }

    dispatch({
      type: 'SHOW_STATUS',
      enablesubmit: false
    });
    const CONFI = Config.headers();
    CONFI.onUploadProgress = (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      dispatch({
        type: 'START_LOAD',
        percent: percentCompleted,
        visible: true
      });
    };

    if (
      (isClientCipa && values[0].size / (1024 * 1024).toFixed(2) > 500) ||
      (!isClientCipa && values[0].size / (1024 * 1024).toFixed(2) > 200)
    ) {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: `Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo com no máximo ${
            isClientCipa ? '500mb' : '200mb'
          }.`
        }
      });
    } else {
      const formData = new FormData();
      formData.append('[canvas_content_images_attributes][][image]', values[0]);
      formData.append('kind', 'image');

      const { data } = await API.post(
        'canvas_publications/' + id + '/canvas_contents',
        formData,
        CONFI
      );

      dispatch({
        type: 'formPost',
        kind: 'image',
        value: data
      });
      dispatch({
        type: 'formPostEdit',
        kind: 'image',
        value: data
      });

      const { canvas_content_images, canvas_content_videos } = data;
      if (
        canvas_content_images.length > 0 ||
        canvas_content_videos.length > 0
      ) {
        dispatch({
          type: 'SHOW_PROGRESS',
          alert: {
            visible: false,
            percentage: 0
          }
        });
      }
      dispatch({
        type: 'NO_SUBMITTING'
      });
      dispatch({
        type: 'HIDE_STATUS',
        enablesubmit: true
      });
      PubSub.publish('re-render-canvas', true);
      dispatch({
        type: 'END_LOAD'
      });
      response = true;
    }
  } catch (error) {
    dispatch({
      type: 'HIDE_STATUS',
      enablesubmit: true
    });
    dispatch({
      type: 'END_LOAD'
    });
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error),
        submessage:
          error.message === 'Não foi possível carregar a imagem'
            ? `O tamanho máximo para arquivos de imagem é de até ${
                hasLargeImageLimit ? '4032 x 4032' : '2000 x 2000'
              } pixels.`
            : ''
      }
    });
  }
  return response;
};

export const saveContentVideo = (id, values) => async (dispatch) => {
  let response = false;
  try {
    dispatch({
      type: 'SHOW_STATUS',
      enablesubmit: false
    });
    const CONFI = Config.headers();
    CONFI.onUploadProgress = (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      dispatch({
        type: 'START_LOAD',
        percent: percentCompleted,
        visible: true
      });
    };

    if (
      (isClientCipa && values[0].size / (1024 * 1024).toFixed(2) > 500) ||
      (!isClientCipa && values[0].size / (1024 * 1024).toFixed(2) > 200)
    ) {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: `Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo com no máximo ${
            isClientCipa ? '500mb' : '200mb'
          }.`
        }
      });
    } else {
      const formData = new FormData();
      formData.append('[canvas_content_videos_attributes][][video]', values[0]);
      formData.append('kind', 'video');
      const { data } = await API.post(
        'canvas_publications/' + id + '/canvas_contents',
        formData,
        CONFI
      );

      dispatch({
        type: 'formPost',
        kind: 'video',
        value: data
      });
      dispatch({
        type: 'formPostEdit',
        kind: 'image',
        value: data
      });
      dispatch({
        type: 'SHOW_PROGRESS',
        alert: {
          visible: false,
          percentage: 0
        }
      });
      dispatch({
        type: 'HIDE_STATUS',
        enablesubmit: true
      });
      dispatch({
        type: 'NO_SUBMITTING'
      });
      PubSub.publish('re-render-canvas', true);
      dispatch({
        type: 'END_LOAD'
      });
      response = true;
    }
  } catch (error) {
    dispatch({
      type: 'END_LOAD'
    });
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
  return response;
};

export const saveContentVideoV2 = (id, values, teamName) => async (
  dispatch
) => {
  let response = false;
  try {
    const f = values[0];
    if (
      (isClientCipa && f.size / (1024 * 1024).toFixed(2) > 500) ||
      (!isClientCipa && f.size / (1024 * 1024).toFixed(2) > 200)
    ) {
      // size validation --> 200mb || || 500mb
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: `Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo com no máximo ${
            isClientCipa ? '500mb' : '200mb'
          }.`
        }
      });
    } else if (f.type !== 'video/mp4') {
      // type validation --> .mp4
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:
            'Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo no formato .mp4.'
        }
      });
    } else {
      var CONFI = {
        headers: {
          'content-type': `${f.type}`,
          'content-length': `${f.size}`
        }
      };
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };

      const cover = await getVideoCover(f, 0.1);

      var CONFI_IMAGE = {
        headers: {
          'content-type': `${cover.type}`,
          'content-length': `${cover.size}`
        }
      };

      const responseLambda = await fetch(
        `https://aheypgto3c.execute-api.us-east-1.amazonaws.com/default/getPresignedURL-1?team=${teamName}`,
        {
          method: 'GET'
        }
      ).then((res) => {
        return res.json();
      });

      const video_filename = responseLambda.filename;

      const result = await API_RANDOM_UPLOAD_VIDEO.put(
        responseLambda.url,
        f,
        CONFI
      );
      const result_upload_image = await API_RANDOM_UPLOAD_VIDEO.put(
        responseLambda.url_image,
        cover,
        CONFI_IMAGE
      );

      const formData = new FormData();
      formData.append(
        '[canvas_content_videos_attributes][][video_filename]',
        video_filename
      );
      formData.append('kind', 'video');

      if (result.status === 200 && result_upload_image.status === 200) {
        const { data } = await API_V2.post(
          'canvas_publications/' + id + '/canvas_contents',
          formData,
          Config.headers()
        );

        dispatch({
          type: 'formPost',
          kind: 'video',
          value: data
        });
        dispatch({
          type: 'formPostEdit',
          kind: 'image',
          value: data
        });
        dispatch({
          type: 'SHOW_PROGRESS',
          alert: {
            visible: false,
            percentage: 0
          }
        });
        dispatch({
          type: 'HIDE_STATUS',
          enablesubmit: true
        });
        dispatch({
          type: 'NO_SUBMITTING'
        });
        PubSub.publish('re-render-canvas', true);
        dispatch({
          type: 'END_LOAD'
        });
        response = true;
      } else {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Houve um erro no upload do vídeo'
          }
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'END_LOAD'
    });
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
  return response;
};

export const saveContentCarousel = (id, values) => async (dispatch) => {
  let response = false;
  try {
    const { canAddAllFiles } = await canAddFiles(values);
    if (!canAddAllFiles) throw new Error('Não foi possível carregar a imagem');

    values.map((file) => {
      if (isGif(file) && file.size / (1024 * 1024).toFixed(2) > 20)
        throw new Error(
          'Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.'
        );
    });

    const invalidImage = await isValidImage(values);
    if (invalidImage.isInvalid) {
      dispatch({
        type: 'HIDE_STATUS',
        enablesubmit: true
      });
      dispatch({
        type: 'END_LOAD'
      });
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: `Imagem em formato não suportado: ${invalidImage.name}`
        }
      });
      return;
    }

    dispatch({
      type: 'SHOW_STATUS',
      enablesubmit: false
    });

    const CONFI = Config.headers();
    CONFI.onUploadProgress = (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      dispatch({
        type: 'START_LOAD',
        percent: percentCompleted,
        visible: true
      });
    };
    const formData = new FormData();
    if (Array.isArray(values)) {
      values.map(function(value, i) {
        formData.append(
          '[canvas_content_images_attributes][][image]',
          values[i]
        );
      });
    }
    formData.append('kind', 'carousel');
    const { data } = await API.post(
      'canvas_publications/' + id + '/canvas_contents',
      formData,
      CONFI
    );

    dispatch({
      type: 'formPost',
      kind: 'carousel',
      value: data
    });
    dispatch({
      type: 'formPostEdit',
      kind: 'image',
      value: data
    });

    dispatch({
      type: 'SHOW_PROGRESS',
      alert: {
        visible: false,
        percentage: 0
      }
    });
    dispatch({
      type: 'HIDE_STATUS',
      enablesubmit: true
    });
    dispatch({
      type: 'NO_SUBMITTING'
    });
    PubSub.publish('re-render-canvas', true);
    dispatch({
      type: 'END_LOAD'
    });
    response = true;
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error),
        submessage:
          error.message === 'Não foi possível carregar a imagem'
            ? 'O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.'
            : ''
      }
    });
  }
  return response;
};

export const saveContentLink = (id, values, title) => async (dispatch) => {
  try {
    dispatch({
      type: 'SHOW_STATUS',
      enablesubmit: false
    });
    const CONFI = Config.headers();
    CONFI.onUploadProgress = (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      dispatch({
        type: 'START_LOAD',
        percent: percentCompleted,
        visible: true
      });
    };
    const formData = new FormData();

    if (!doesContainHttp(values.link_button)) {
      values.link_button = `https://${values.link_button}`;
    }

    formData.append('link', values.link_button);
    formData.append('title', values.name_button);
    formData.append('kind', 'link');

    const { data } = await API.post(
      'canvas_publications/' + id + '/canvas_contents',
      formData,
      CONFI
    );

    dispatch({
      type: 'formPost',
      kind: 'link',
      value: data
    });
    dispatch({
      type: 'formPostEdit',
      kind: 'image',
      value: data
    });
    dispatch({
      type: 'SHOW_PROGRESS',
      alert: {
        visible: false,
        percentage: 0
      }
    });
    dispatch({
      type: 'HIDE_STATUS',
      enablesubmit: true
    });
    dispatch({
      type: 'NO_SUBMITTING'
    });
    PubSub.publish('re-render-canvas', true);
    dispatch({
      type: 'END_LOAD'
    });
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

export const getMorePublications = (page = 1, kind, raffle = '') => async (
  dispatch
) => {
  const randomId = parseInt(Math.random() * 10000000);
  try {
    dispatch({
      type: 'SET_REQUEST_ACTIVE',
      randomIdRequest: randomId
    });
    dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

    const kindParam = `&kind=${kind}`;

    const withRaffle = `&with_content=${raffle}`;

    const res = await Axios.get(
      `${
        style.url.urlClient
      }api/v2/canvas_publications/?page=${page}&per_page=10${roleAdmin()}&source=admin${kindParam}${withRaffle}`,
      Config.headers()
    );

    dispatch({
      type: 'GET_MORE_PUBLICATIONS',
      publication_list: PostApi.parsePostV2(res.data),
      page: page
    });

    dispatch({
      type: 'NO_SUBMITTING'
    });

    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
    dispatch({
      type: 'DELETE_REQUEST_ACTIVE',
      randomIdRequest: randomId
    });
  } catch (error) {
    dispatch({
      type: 'DELETE_REQUEST_ACTIVE',
      randomIdRequest: randomId
    });
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};
