
  export const style={
    inpuStyle:{
      colorInput:"#8cb02c"
    },
    url:{
      urlClient:"https://cibra.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/cibra.png"
    },
    CurrencyName:{
      name:"Cibra/Cibras"
    },
    teamName:{
      name:"Time Cibra"
    },
    hasToken: false,
    hostHeader: "cibra"
  }
