import React, { useEffect, useState } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';

import styled from 'styled-components';

import { style } from '../../style';
import {
  required,
  maxLength190,
  minValue0,
  dateValue
} from '../validation/FormValidation';
import { TextFieldTitle } from '../utils/FormsComponents';
import { DatePickerForm, NewDropZoneCover } from '../utils_form/FormComponents';

import PreviewCreateCanvas from '../canvas/components/previews/CreatePreview';
import EmojiPicker from '../utils/EmojiPicker/EmojiPicker';
import IconDelete from '../utils/img/ic-delete.png';

import PostApi from '../../Logicas/PostApi';
import ComponentHeader from '../utils/ComponenteHeader';

import DashboardButton from '../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import ToolsButton from '../Tools/ToolsList/Components/ToolsButton/ToolsButton';

import './createOrEdit.css';
import ProgressToast from '../_shared/ProgressToast/ProgressToast';

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

const hasLargeImageLimit = ['youseed', 'sample', 'MRN', 'pelando'].includes(
  style.teamName.name
);

export function CreatePost(props) {
  const [title, setTitle] = useState('');
  const [selectionStart, setSelectionStart] = useState(0);
  const [file, setFile] = useState(null);

  const { handleSubmit } = props;

  useEffect(() => {
    props.updateStatusRequest('');
  }, []);

  const draftCanBeCreated = () => {
    if (props.statusRequest === 'Salvando...') return false;

    if (props.isForm) {
      if (props.new_title !== undefined && props.end_date !== undefined)
        return true;
      return false;
    } else if (props.new_title !== undefined) return true;

    return false;
  };

  const saveCanvasInitial = () => {
    const isForm = props.end_date !== null && props.end_date !== undefined;
    props.postCreate(
      {
        title: props.new_title,
        cover_form: props.cover_form,
        end_date: props.end_date
      },
      isForm
    );
  };

  function preserveCursor() {
    setSelectionStart(document.querySelector('textarea#title').selectionStart);
  }

  function handleOnBlur() {
    preserveCursor();
  }

  function zeroTitle(target) {
    if (target.nodeName === 'IMG') {
      props.dispatch(change('new_create_post', 'new_title', ''));
    }
  }

  function zeroEndDate(target) {
    if (target.nodeName === 'IMG') {
      props.dispatch(change('new_create_post', 'end_date', null));
    }
  }

  return (
    <div>
      <MuiThemeProvider muiTheme={muiTheme}>
        <ComponentHeader
          enableNotification
          enablesubmit={true}
          description={props.load_status}
          tooltip={
            <p>
              Atualizar publicação
              <br />
            </p>
          }
          actionSecondarys={[
            {
              name: 'Agendar',
              action: props.showScheduleModalForTrue
            }
          ]}
          name={
            props.isRaffle
              ? 'Novo Sorteio'
              : props.isForm
              ? 'Novo Formulário'
              : 'Nova Publicação'
          }
          isNewCreatePost={true}
          nameaction="Publicar"
        />

        <form
          onSubmit={handleSubmit(saveCanvasInitial)}
          className="container"
          style={{
            boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1)',
            display: 'flex',
            paddingBottom: '10px',
            maxWidth: '100%',
            width: '85%'
          }}
        >
          <div
            style={{
              width: '65%',
              minHeight: '67vh',
              maxHeight: '67vh',
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <div
              className="form-createoredit"
              style={{ width: '100%', paddingTop: '30px' }}
            >
              <div className="row">
                <div className="col-12">
                  <div className="img-create">
                    <div className="cover-img-publish cover-img-publish-create-post">
                      <Field
                        name="cover_form"
                        disableClick={false}
                        component={(e) =>
                          NewDropZoneCover({
                            ...e,
                            dispatchImageError: props.dispatchImageError,
                            dispatchGifError: props.dispatchGifError
                          })
                        }
                        onChange={(e) => setFile(e[0])}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ width: '78%' }}>
                <div className="col-md-12">
                  {
                    <div className="cancel" style={{ width: '100%' }}>
                      <img
                        style={{ marginLeft: '90%', zIndex: '3' }}
                        src={IconDelete}
                        alt=""
                        onClick={(e) => zeroTitle(e.target)}
                      />
                    </div>
                  }
                  <Field
                    name={'new_title'}
                    rows={1}
                    validate={[required, maxLength190]}
                    id="title"
                    label="Título da publicação*"
                    hintText={''}
                    component={TextFieldTitle}
                    type="text"
                    value={title}
                    onBlur={handleOnBlur}
                    onChange={(event) => setTitle(event.target.value)}
                  />
                  <p className="view-characters-description">
                    {title.length > 0 ? (
                      title.length <= 190 ? (
                        `${190 - title.length} caracteres restantes.`
                      ) : (
                        <span className="text-red">
                          Quantidade de caracteres acima do permitido.
                        </span>
                      )
                    ) : (
                      <span className="text-red">
                        O título não pode ser vazio.
                      </span>
                    )}
                  </p>
                </div>
                {
                  <div
                    className="col-md-1"
                    style={{
                      position: 'relative',
                      marginLeft: '85%',
                      marginTop: '-105px'
                    }}
                  >
                    <div
                      className="emoji-modal__container"
                      style={{ position: 'relative' }}
                    >
                      <EmojiPicker
                        element="textarea#title"
                        propToChange="new_title"
                        parentProps={props}
                        cursorPosition={selectionStart}
                      />
                    </div>
                  </div>
                }
              </div>
              <div
                className="row"
                style={{
                  width: '78%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {props.isForm && !props.isRaffle && (
                  <div className="col-md-6">
                    {
                      <div className="cancel" style={{ width: '100%' }}>
                        <img
                          style={{ marginLeft: '85%', zIndex: '3' }}
                          src={IconDelete}
                          alt=""
                          onClick={(e) => zeroEndDate(e.target)}
                        />
                      </div>
                    }
                    <label
                      className="label-chindren-date"
                      style={{ borderBottom: '1px solid #3B3B3B4D' }}
                    >
                      <DatePickerForm
                        validations={[required, dateValue]}
                        name="end_date"
                        label="Prazo para respostas*"
                        isCanvasFormInitial={true}
                      />
                    </label>
                    <div>
                      <span className="note-schedule">
                        Até quando o formulário aceitará respostas
                      </span>
                    </div>
                  </div>
                )}

                <div
                  className="col-md-6"
                  style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      margin: '0px !important',
                      padding: '0 !important'
                    }}
                  >
                    <DashboardButton
                      withoutMargin
                      label={'Cancelar'}
                      onClick={() => browserHistory.push('/post/publication')}
                      style={{ width: '100px' }}
                    />
                  </div>
                  <div
                    style={{
                      margin: '0px !important',
                      padding: '0 !important'
                    }}
                  >
                    <ToolsButton
                      disabled={!draftCanBeCreated()}
                      isDisabled={!draftCanBeCreated()}
                      label="Continuar"
                      onClick={handleSubmit(saveCanvasInitial)}
                      style={{ width: '100px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PreviewCreateCanvas
            title={title}
            elements={[]}
            file={file}
            isCreate={true}
            user={{
              name: localStorage.getItem('UserName'),
              image: localStorage.getItem('UserImage')
            }}
            end_date={props.end_date}
          />
        </form>

        <ProgressToast statusRequest={props.statusRequest} />
      </MuiThemeProvider>
    </div>
  );
}

let InitializeFromStateForm = reduxForm({
  form: 'new_create_post'
})(CreatePost);

const mapDispatchToProps = (dispatch) => {
  return {
    postCreate: (values, isForm) => {
      dispatch({
        type: 'UPDATE_STATUS_REQUEST',
        message: 'Salvando...'
      });
      dispatch(PostApi.saveCanvasInitial(values, isForm));
    },
    updateStatusRequest: (message) => {
      dispatch({
        type: 'UPDATE_STATUS_REQUEST',
        message: message
      });
    },
    showScheduleModalForTrue: () => {
      dispatch({ type: 'SCHEDULE_MODAL_SHOW' });
    },
    dispatchImageError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: 'Não foi possível carregar a imagem',
          submessage: `O tamanho máximo para arquivos de imagem é de até ${
            hasLargeImageLimit ? '4032 x 4032' : '2000 x 2000'
          } pixels.`
        }
      });
    },
    dispatchGifError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:
            'Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.'
        }
      });
    }
  };
};
const selector = formValueSelector('new_create_post');
InitializeFromStateForm = connect(
  (state) => ({
    editpostform: state.editpostform,
    creatpost: state.createpost,
    cover_form: selector(state, 'cover_form'),
    title: selector(state, 'title'),
    new_title: selector(state, 'new_title'),
    end_date: selector(state, 'end_date'),
    statusRequest: state.editpostform.statusRequest,
    isForm: state.getTypeForm,
    send_push: state.editpostform.send_push,
    user: state.userReducer,
    isRaffle: state.draw.isDrawType
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
