import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import styled from 'styled-components';
import moment from "moment";

import { Player } from 'video-react';
import ReactHtmlParser from 'react-html-parser';
import { Carousel } from 'react-responsive-carousel';

import { style } from '../../../../style';
import checkbox_on from '../../../utils/img/checkbox_on.png';
import checkbox_off from '../../../utils/img/checkbox_off.png';
import radiobutton_on from '../../../utils/img/radiobutton_on.png';
import radiobutton_off from '../../../utils/img/radiobutton_off.png';
import not_img from '../../../utils/img/ico-external.png';
import avatar from '../../../utils/img/ic-avatar.png';

import { ReactComponent as ArrowRight } from '../../../CreateOrEditPost/icons/arrow-right.svg';
import { ReactComponent as IconSave } from '../../../CreateOrEditPost/icons/ic-save.svg';
import { ReactComponent as IconHeart } from '../../../CreateOrEditPost/icons/ic-heart.svg';
import { ReactComponent as IconMessage } from '../../../CreateOrEditPost/icons/ic-message.svg';
import { ReactComponent as IconPin } from '../../../CreateOrEditPost/icons/ic-pin.svg';

require('./createpreview.css');

const TagsContainer = styled.div`
  width: 80%;
  padding: 15px 5px 5px;
  margin: 30px auto 15px;

  background-color: #f4f6fb;
  color: #808080;

  gap: 10px;
  font-size: 14px;

  > span {
    margin: 0 0 10px 10px;
  }

  > .tags-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 5px 5px 10px;

    > .tag-preview {
      padding: 5px;
      background-color: ${style.inpuStyle.colorInput};
      border-radius: 8px;
      color: white;
      font-size: 15px;
    }
  }
`;

const ActionsLikeAndComment = styled.div`
  width: 80%;
  height: 60px;
  margin: 0 auto;
  border-radius: 6px;

  background-color: ${style.inpuStyle.colorInput};
  color: white;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;

    gap: 6px;
    font-size: 14px;
  }

  .divisor {
    margin: auto 0;
    height: 70%;
    background: white;
    width: 1px;
  }
`;

const CoverCard = styled.div`
  width: 80%;
  margin: 0 auto 30px;

  display: flex;
  flex-direction: column;

  border-radius: 8px 8px 0px 0px;

  .img-form-result {
    margin-bottom: 5px !important;
  }

  .video-react,
  .video-react-video,
  .img-form-result {
    border-radius: 8px 8px 0px 0px;
  }

  span.preview-title {
    color: #4b4e5c;
    font-family: 'Rubik', sans-serif;
    padding: 0 10px;
  }

  div.last-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .user-info {
      display: flex;
      align-items: center;

      img {
        height: 35px;
        width: 35px;
        border-radius: 100%;
      }

      .user-and-date {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        margin-left: 10px;

        font-size: 10px;

        p {
          font-size: 12px;
          color: ${style.inpuStyle.colorInput};
          margin-bottom: 0;
        }
        span {
          color: #808080;
        }
      }
    }

    .user-icons{
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 1rem;

      svg#fixedPost > path{
        fill: ${style.inpuStyle.colorInput};
      }

      svg#savePost > path{
        stroke: ${style.inpuStyle.colorInput};
      }
    }
  }

  background-color: #f4f6fb;
`;

const Link = styled.a`
  background-color: rgb(244, 246, 251);
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 5px;
  transition: all .3s ease-out;
  cursor: pointer;

  word-wrap: break-word;
  
  p{
    text-decoration: none;
    color: ${style.inpuStyle.colorInput};
    font-size: 18px;
    line-height: 24px;
    margin: 0;
  }

  svg path{
    stroke: #808080;
  }

  &:hover{
    text-decoration: none;
    background-color: ${style.inpuStyle.colorInput};
    p{
      color: white;
    }

    svg path{
      stroke: white;
    }

  }
`;

export default class PreviewCreateCanvas extends Component {
  componentDidMount() {
    PubSub.subscribe(
      're-render-canvas',
      function(render) {
        this.forceUpdate();
      }.bind(this)
    );
  }
  render() {
    const imageAvatar = this.props.user && this.props.user.image && this.props.user.image.url || avatar;
    return (
      <div
        className="col-md-4"
        style={{
          minHeight: '67vh',
          maxHeight: '67vh',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <div
          className="body-preview"
          style={{ borderTop: 'none', boxShadow: 'none' }}
        >
          <div className="header-preview-post">
            <div className="header-preview-post-content">
              <h2>Pré-visualização</h2>
              <span>Veja como seu post está antes de publicá-lo.</span>
            </div>
          </div>
          <CoverCard>
            {this.props.elements.length > 0 && this.props.elements[0].kind && (
              <div className="img-form-result">
                {this.props.elements[0].kind === 'image' && (
                  <img
                    className="img-form-result"
                    src={
                      this.props.elements[0].canvas_content_images[0].image.url
                    }
                    alt="imagem-formulario"
                  />
                )}
                {this.props.elements[0].kind === 'video' && (
                  <Player
                    style={{ width: '100%', height: 'auto' }}
                    src={
                      this.props.elements[0].canvas_content_videos[0].video.url
                    }
                  />
                )}
                {this.props.elements[0].kind === 'carousel' && (
                  <Carousel showArrows={true} swipeable={false}>
                    {this.props.elements[0].canvas_content_images.length > 0 &&
                      this.props.elements[0].canvas_content_images.map(function(
                        image
                      ) {
                        return (
                          <div>
                            <img
                              style={{
                                'object-fit': 'cover'
                              }}
                              src={image.image.url}
                              alt="imagem do carrossel"
                            />
                          </div>
                        );
                      })}
                  </Carousel>
                )}
              </div>
            )}

            {this.props.isCreate && (
              <div className="img-form-result">
                {this.props.file && this.props.file.type === 'video/mp4' ? (
                  <Player
                    style={{ width: '100%', height: 'auto' }}
                    src={this.props.file.preview}
                  />
                ) : (
                  <img
                    className="img-form-result"
                    src={this.props.file ? this.props.file.preview : not_img}
                    alt="imagem-formulario"
                  />
                )}
              </div>
            )}

            <span className="preview-title">
              {this.props.title ? this.props.title : 'Titulo da Publicação'}
            </span>

            <div className="last-line">
              <div className="user-info">
                <img
                  style={{background: `url(${imageAvatar}) center / cover`}}
                />

                <div className="user-and-date">
                  <p>
                    {(this.props.user && this.props.user.name) ||
                      'Nome do usuário'}
                  </p>
                  <span>
                    {
                      this.props.date && moment(new Date(this.props.date)).utc().locale("pt-br").format("L")
                      ||'Data da publicação'
                    }  
                    { 
                      this.props.end_date && ` - Respostas até ${moment(new Date(this.props.end_date)).utc().locale("pt-br").format("L")}`
                    }
                  </span>
                </div>
              </div>

              <div className="user-icons">
                {this.props.isFixedPost && <IconPin id="fixedPost"/>}
                <IconSave id="savePost"/>
              </div>

            </div>
          </CoverCard>

          {this.props.isCreate ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: '10px',
                  marginBottom: '24px'
                }}
                className="container-fluid"
              >
                <div className="body-question-preview">
                  {this.props.elements &&
                    this.props.elements.map(function(content, index) {
                      if (content.kind === 'text') {
                        return (
                          <div className="text-preview-element">
                            {ReactHtmlParser(content.text)}
                          </div>
                        );
                      }
                      if (content.kind === 'link') {
                        return (
                          <div style={{marginBottom: "20px"}}>
                            <div
                              className=" btn-add-component"
                              style={{ boxShadow: 'none' }}
                            >
                              <Link
                                href={`${content.link}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>
                                  {content.title.length > 30
                                    ? content.title.slice(0, 30) + '...'
                                    : content.title}
                                </p>
                                <ArrowRight />
                              </Link>
                            </div>
                          </div>
                        );
                      }
                      if (
                        content.kind === 'image' &&
                        content.canvas_content_images.length === 1 &&
                        index > 0
                      ) {
                        return (
                          <img
                            className="img-form-result"
                            src={content.canvas_content_images[0].image.url}
                            alt="imagem-formulario"
                          />
                        );
                      }
                      if (
                        content.kind === 'carousel' &&
                        content.canvas_content_images.length > 0 &&
                        index > 0
                      ) {
                        return (
                          <Carousel showArrows={true} swipeable={false}>
                            {content.canvas_content_images.length > 0 &&
                              content.canvas_content_images.map(function(
                                image
                              ) {
                                return (
                                  <div>
                                    <img
                                      style={{
                                        'object-fit': 'cover'
                                      }}
                                      src={image.image.url}
                                      alt="imagem do carrossel"
                                    />
                                  </div>
                                );
                              })}
                          </Carousel>
                        );
                      }
                      if (content.kind === 'video') {
                        if (
                          content.canvas_content_videos.length > 0 &&
                          index > 0
                        ) {
                          return (
                            <Player
                              style={{ width: '100%', height: 'auto' }}
                              src={content.canvas_content_videos[0].video.url}
                            />
                          );
                        }
                      }
                      if (content.survey) {
                        return (
                          <div className="container-fluid-answer">
                            <div className="title-preview-form">
                              {content.survey.question_only.text}
                            </div>
                            <div className="preview-form">
                              <div>
                                {content.survey.optionOnly.map(function(
                                  survey,
                                  index
                                ) {
                                  return (
                                    <div className="details-survey segmentation-container">
                                      <img
                                        className="img-alteration"
                                        src={
                                          index === 1
                                            ? radiobutton_on
                                            : radiobutton_off
                                        }
                                        alt="checked-box"
                                      />
                                      <p className="text-details-question">
                                        {survey.title}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (content.kind === 'survey') {
                        return (
                          <div className="container-fluid-answer">
                            <div className="title-preview-form">
                              {content.text}
                            </div>
                            <div className="preview-form">
                              <div>
                                {content.content_survey.map(function(
                                  survey,
                                  index
                                ) {
                                  return (
                                    <div className="details-survey segmentation-container">
                                      <img
                                        className="img-alteration"
                                        src={
                                          index === 1
                                            ? radiobutton_on
                                            : radiobutton_off
                                        }
                                        alt="checked-box"
                                      />
                                      <p className="text-details-question">
                                        {survey.title}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (content.quiz) {
                        return (
                          <div className="container-fluid-answer">
                            <div className="title-preview-form">
                              {content.quiz.question_multiple.text}
                            </div>
                            <div className="preview-form">
                              <div>
                                {content.quiz.option_multiple.map(function(
                                  survey,
                                  index
                                ) {
                                  return (
                                    <div className="segmentation-container details-survey">
                                      <img
                                        className="img-alteration"
                                        src={
                                          index === 1
                                            ? checkbox_on
                                            : checkbox_off
                                        }
                                        alt="checked-box"
                                      />
                                      <p className="text-details-question">
                                        {survey.title}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (content.kind === 'quiz') {
                        return (
                          <div className="container-fluid-answer">
                            <div className="title-preview-form">
                              {content.text}
                            </div>
                            <div className="preview-form">
                              <div>
                                {content.content_survey.map(function(
                                  survey,
                                  index
                                ) {
                                  return (
                                    <div className="segmentation-container details-survey">
                                      <img
                                        className="img-alteration"
                                        src={
                                          index === 1
                                            ? checkbox_on
                                            : checkbox_off
                                        }
                                        alt="checked-box"
                                      />
                                      <p className="text-details-question">
                                        {survey.title}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (content.open_question) {
                        return (
                          <div className="container-fluid-answer">
                            <div className="title-preview-form">
                              {
                                content.open_question.content_open_question
                                  .question
                              }
                            </div>
                            <div className="preview-form">
                              <input
                                type="text"
                                className="input-preview-question"
                                placeholder="Digite sua resposta"
                              />
                            </div>
                          </div>
                        );
                      } else if (content.kind === 'open_question') {
                        return (
                          <div className="container-fluid-answer">
                            <div className="title-preview-form">
                              {content.text}
                            </div>
                            <div className="preview-form">
                              <input
                                type="text"
                                className="input-preview-question"
                                placeholder="Digite sua resposta"
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>

              {(this.props.hasLikes || this.props.hasComments) &&
                (
                  <ActionsLikeAndComment>
                    {
                      this.props.hasLikes &&
                        <div className="action">
                          <IconHeart />
                          Curtir
                        </div>
                    }

                    {this.props.hasLikes && this.props.hasComments && <div className="divisor" />}

                    {
                      this.props.hasComments &&
                        <div className="action">
                          <IconMessage />
                          Comentários
                        </div>
                    }
                  </ActionsLikeAndComment>
                )
              }

              {/* Attention: Hidden tags */}
              {/* <TagsContainer>
                <span>Tags</span>
                <div className="tags-preview">
                  {this.props.tags &&
                    this.props.tags.map((tag) => (
                      <div className="tag-preview">{tag.value}</div>
                    ))}
                </div>
              </TagsContainer> */}
            </>
          )}
        </div>
      </div>
    );
  }
}
