import axios from 'axios';
import { storeCopy } from '../../index';
import config from './base.config';
import { style } from '../../style';

const APIDelete = axios.create({
  baseURL: config.apiurlClientYouseed,
});

if (!!style.hostHeader) {
  APIDelete.defaults.headers.common['host-header'] = style.hostHeader;
}

let pendingRequests = 0;

APIDelete.interceptors.request.use(
  function (config) {
    pendingRequests++;
    storeCopy.dispatch({
      type: 'SET_PENDING_REQUESTS',
      pendingRequests,
    });

    let acesstoken = localStorage.getItem("data['at']");
    let client = localStorage.getItem("data['c']");
    let contenttype = localStorage.getItem("data['ct']");
    let resourcetype = localStorage.getItem("data['rt']");
    let uid = localStorage.getItem("data['uid']");
    let admin = localStorage.getItem("data['admin']");
    let users = localStorage.getItem("data['USERS']");

    if (
      acesstoken &&
      client &&
      contenttype &&
      resourcetype &&
      uid &&
      acesstoken &&
      admin &&
      users
    ) {
      config.headers.common['access-token'] = acesstoken;
      config.headers.common['client'] = client;
      config.headers.common['uid'] = uid;
      config.headers.common['resource-type'] = resourcetype;
      config.headers.common['provider'] = 'email';
      config.headers.common['admin_id'] = admin;
      config.headers.common['USERS'] = users;
      return config;
    } else {
      return config;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

APIDelete.interceptors.response.use(
  function (response) {
    pendingRequests--;
    storeCopy.dispatch({
      type: 'SET_PENDING_REQUESTS',
      pendingRequests,
    });

    return response;
  },
  function (error) {
    storeCopy.dispatch({
      type: 'SET_PENDING_REQUESTS',
      pendingRequests: 0,
    });

    if (error.response && error.response.status === 401) {
      window.location.pathname = '/';
    }

    return Promise.reject(error);
  }
);

export default APIDelete;
