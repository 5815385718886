import React, { Component } from 'react';
import ButtonDefault, {
  DropZoneAvatar,
  TextFieldDefault
} from '../utils/FormsComponents';
import {
  SelectForm,
  SelectFormId,
  LongTextFieldTitle,
  TextFieldForm,
  AutoCompleteForm,
  ToogleNoReduxForm
} from '../utils_form/FormComponents';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TagApi from '../../Logicas/TagApi';
import SectorApi from '../../Logicas/SectorApi';
import UserApi from '../../Logicas/UserApi';
import { Field, reduxForm } from 'redux-form';
import { Body } from '../utils/BodyComponente';
import UserTeamToggle from './components/UserTeamToggle/UserTeamToggle';
import {
  required,
  validAge,
  minValue0,
  minLength6
} from '../validation/FormValidation';
import ComponentHeader from '../utils/ComponenteHeader';
import { dateMask, phoneMask, cepMask } from '../normalize/Normalize';
import { connect } from 'react-redux';
import { DialogAlert } from '../utils/DailogComponentes';
import { formValueSelector, change } from 'redux-form';
import { browserHistory } from 'react-router';
import teamSettings from '../utils/TeamsSettings/generalSettings';
import API from '../restservice/api.instance';
import { style } from '../../style';

const {
  userTypes,
  displayGenderField,
  hasBrands,
  hasAcademyRole
} = teamSettings();
class EditUser extends Component {
  constructor() {
    super();
    this.state = {
      tags: [],
      sectors: [],
      tagsuser: [],
      contact_email: '',
      academyRole: false,
      user_roles: '',
      formAddress: {}
    };
    this.updateUser = this.updateUser.bind(this);
    this.rePassword = this.rePassword.bind(this);
    this.handleFormAddress = this.handleFormAddress.bind(this);
  }

  componentWillMount() {
    style.teamName.name !== 'Pintou Parceria Suvinil' &&
      this.props.getSectorUser();
    this.props.getStates();
    if (this.props.state !== undefined && this.props.state !== false)
      this.props.getCities(this.props.state);
    if (
      this.props.zip_code !== undefined &&
      typeof this.props.zip_code === 'string'
    )
      this.handleFormAddress('zip_code', this.props.zip_code.replace('-', ''));
    const { hasExternalUsers } = teamSettings();
    if (this.props.initialValues) {
      this.setState({
        contact_email: this.props.initialValues.contact_email,
        hasExternalUsers
      });
    }
  }

  componentDidMount() {
    let roles = JSON.parse(localStorage.getItem('UserRoles'));
    let user_role = roles.map(function(role) {
      return role.name;
    })[0];

    this.setState({
      user_roles: user_role
    });
  }

  componentDidUpdate() {
    if (
      style.teamName.name === 'Pintou Parceria Suvinil' ||
      style.teamName.name === 'Vedacit' ||
      style.teamName.name === 'mestreconstrutor'
    ) {
      const newForm = this.state.formAddress;
      let newCity =
        this.props.listCities !== undefined &&
        this.props.citySelected !== undefined &&
        this.props.citySelected.name_sector !== undefined
          ? this.props.listCities.find(
              (city) => city.name_sector === this.props.citySelected.name_sector
            )
          : [];

      if (
        newCity !== undefined &&
        newCity.length === 0 &&
        this.props.citySelected.name_sector === undefined &&
        this.props.city !== undefined &&
        this.props.cityName !== undefined
      ) {
        newCity = this.props.listCities.find(
          (city) => city.name_sector === this.props.cityName
        );
      }
      if (newCity !== undefined) {
        newForm['city'] = newCity.id_sector;
        newForm['cityName'] = newCity.name_sector;
        this.props.setSelectedCity(newCity);
      } else if (this.props.citySelected.name_sector === '')
        this.props.setSelectedCity({ name_sector: '' });

      const newState =
        this.props.listStates !== undefined &&
        this.props.stateSelected !== undefined &&
        this.props.stateSelected.id_sector !== undefined
          ? this.props.listStates.find(
              (state) => state.id === this.props.stateSelected.id
            )
          : [];
      if (newState !== undefined && newState.length !== 0) {
        newForm['state'] = newState.id_sector;
        this.props.getCities(newState.id_sector);
      }

      this.props.dispatch(change('edituser', 'state', newForm['state']));
      this.props.dispatch(change('edituser', 'city', newForm['city']));
      this.props.dispatch(change('edituser', 'cityName', newForm['cityName']));
    }
  }

  componentWillReceiveProps() {
    const {
      initialValues: { permission_userArray }
    } = this.props;

    if (permission_userArray !== undefined)
      this.setState({
        academyRole: permission_userArray.includes('Academy')
      });
  }

  updateUser(values) {
    const {
      initialValues: { contacts },
      userEdit: { brands }
    } = this.props;

    const { academyRole } = this.state;

    const newContacts = contacts.map((contact) => ({
      kind: contact.kind,
      value: values[`contact_${contact.kind}`],
      id: contact.id
    }));

    values.userBrands = brands;
    values.id = this.props.initialValues.id;

    if (
      style.teamName.name === 'Pintou Parceria Suvinil' ||
      style.teamName.name === 'Vedacit' ||
      style.teamName.name === 'mestreconstrutor'
    ) {
      this.props.listStates.map((state) => {
        if (state.id_sector === values.state) values.stateName = state.id;
      });
      if (
        this.props.citySelected.name_sector !== '' &&
        this.state.formAddress['city'] !== '' &&
        this.props.allowRequestCity
      ) {
        this.props.editUser(values, newContacts, academyRole);
      } else this.props.dispatchError();
    } else {
      this.props.editUser(values, newContacts, academyRole);
    }
  }
  rePassword(values) {
    this.props.newPassWord(values);
  }

  handleFormAddress(index, value) {
    const newForm = {};
    newForm.city = this.props.city;
    newForm.cityName = this.props.cityName;
    newForm.state = this.props.state;
    newForm.neighborhood = this.props.neighborhood;
    newForm.street = this.props.street;

    switch (index) {
      case 'city':
        this.props.setAllowRequestCity(true);
        break;
      case 'state':
        newForm['city'] = '';
        newForm['cityName'] = '';
        this.props.dispatch(change('edituser', 'city', newForm['city']));
        this.props.dispatch(
          change('edituser', 'cityName', newForm['cityName'])
        );
        this.props.setSelectedCity({ name_sector: '' });
        this.setState({ formAddress: newForm });
        this.props.setAllowRequestCity(false);
        break;
      case 'zip_code':
        if (value.length > 8) return;
        if (value.length === 8) {
          fetch(`https://viacep.com.br/ws/${value}/json/`).then((response) => {
            response.json().then((data) => {
              const { localidade, logradouro, uf, bairro } = data;
              newForm['street'] = logradouro;
              newForm['neighborhood'] = bairro;
              const newState =
                this.props.listStates !== undefined
                  ? this.props.listStates.find((state) => state.id === uf)
                  : [];
              if (newState !== undefined) {
                newForm['state'] = newState.id_sector;
                this.props.getCities(newState.id_sector);
                this.props.setSelectedState(newState);
                this.props.setSelectedCity({ name_sector: localidade });
              }

              this.setState({ formAddress: newForm });
              this.props.dispatch(
                change('edituser', 'street', newForm['street'])
              );
              this.props.dispatch(
                change('edituser', 'state', newForm['state'])
              );
              this.props.dispatch(
                change('edituser', 'neighborhood', newForm['neighborhood'])
              );
            });
          });
        }
        break;
    }
    newForm[index] = value;
    this.setState({ formAddress: newForm });
  }

  render() {
    const { handleSubmit, submitting, initialValues } = this.props;
    const { academyRole } = this.state;
    const isAppCustom =
      style.teamName.name === 'Pintou Parceria Suvinil' ||
      style.teamName.name === 'Vedacit' ||
      style.teamName.name === 'mestreconstrutor';

    if (Object.values(initialValues).length) {
      let admin = localStorage.getItem('ADMIN');
      return (
        <MuiThemeProvider>
          <ComponentHeader
            disabled={submitting}
            tooltip={
              <p>
                Crie um novo usuário
                <br />
              </p>
            }
            actions={[]}
            name="Usuários"
            nameaction="Salvar"
            onClick={handleSubmit(this.updateUser)}
          />
          <Body>
            <div className="row">
              <div className="col-md-7">
                <LongTextFieldTitle
                  name="name_user"
                  type="text"
                  validations={[required]}
                  id="name"
                  label="Nome do Usuário"
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="img"
                  id="user_image"
                  component={(e) =>
                    DropZoneAvatar({
                      ...e,
                      dispatchImageError: this.props.dispatchImageError
                    })
                  }
                  type="text"
                />
              </div>
            </div>
            <div style={{ marginTop: '-5%' }} className="row">
              <div className="col-md-4">
                <TextFieldForm
                  name="email_user"
                  validations={[required]}
                  id="email_user"
                  floatingLabelText="Acesso *"
                  type="text"
                  disabled={this.state.user_roles === 'manager'}
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="birthday_user"
                  validate={[validAge]}
                  normalize={dateMask}
                  id="user_image"
                  label="Aniversário"
                  component={TextFieldDefault}
                  type="text"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <TextFieldForm
                  name="score_user"
                  disabled={true}
                  validations={[minValue0]}
                  id="nameuser"
                  floatingLabelText="Pontuação *"
                  type="text"
                  isFormattedNumber
                />
              </div>
              <div className="col-md-4">
                <Field
                  type="text"
                  name="admitted_at"
                  normalize={dateMask}
                  id="admitted_at"
                  floatingLabelText="Data de admissão"
                  component={TextFieldDefault}
                />
              </div>
            </div>
            {style.teamName.name !== 'Pintou Parceria Suvinil' && (
              <div className="row">
                <div className="col-md-4">
                  <SelectFormId
                    name="sector_id"
                    id="user_image"
                    validations={[required]}
                    elements={this.props.sectorReducer}
                    label="Setor *"
                  />
                </div>
                <div
                  className="col-md-4"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <TextFieldForm
                    type="text"
                    name="complement_sector"
                    id="complement_sector"
                    floatingLabelText="Complemento"
                  />
                  <span
                    style={{
                      zIndex: 2,
                      fontSize: '12px',
                      fontFamily: '"Rubik", sans-serif',
                      color: 'rgba(0,0,0,0.3)'
                    }}
                  >
                    Campo de preenchimento livre, Empresa / Filial / Equipe /
                    Tribo / Área / Cargo / Função
                  </span>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-4">
                <TextFieldForm
                  name="contact_email"
                  disabled={false}
                  validations={[required]}
                  id="contact_email_id"
                  floatingLabelText="E-mail *"
                />
              </div>
              <div className="col-md-4">
                <Field
                  type="tel"
                  name="contact_phone"
                  normalize={phoneMask}
                  id="contact_phone_id"
                  floatingLabelText="Telefone (com DDD)"
                  component={TextFieldDefault}
                />
              </div>
            </div>
            <div className="row">
              {displayGenderField && (
                <div className="col-md-4">
                  <SelectForm
                    name="sex_user"
                    validations={[required]}
                    multiple={false}
                    id="user_image"
                    label="Gênero"
                    elements={[
                      'Mulher cis',
                      'Mulher trans',
                      'Homem cis',
                      'Homem trans',
                      'Não binário',
                      'Prefere não se identificar',
                      'Outros'
                    ]}
                  />
                </div>
              )}
              {this.props.gender &&
                this.props.gender.includes('Outros') &&
                displayGenderField && (
                  <div className="col-md-4">
                    <TextFieldForm
                      name="custom_gender"
                      validations={[required]}
                      id="custom_gender"
                      floatingLabelText="Outros gêneros*"
                      type="text"
                    />
                  </div>
                )}
            </div>

            {isAppCustom && (
              <>
                <div className="row">
                  <div className="col-md-4">
                    <Field
                      type="cep"
                      name="zip_code"
                      normalize={cepMask}
                      id="zip_code"
                      floatingLabelText="CEP"
                      component={TextFieldDefault}
                      onChange={(e) =>
                        this.handleFormAddress(
                          'zip_code',
                          e.target.value.replaceAll('-', '')
                        )
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <TextFieldForm
                      type="text"
                      name="street"
                      id="street"
                      floatingLabelText="Rua/Av."
                      component={TextFieldDefault}
                      onChange={(e) =>
                        this.handleFormAddress('street', e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <TextFieldForm
                      name="number"
                      disabled={false}
                      validations={[minValue0]}
                      id="number"
                      floatingLabelText="Número"
                      type="number"
                      onChange={(e) =>
                        this.handleFormAddress('number', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <TextFieldForm
                      type="text"
                      name="complement"
                      id="complement"
                      floatingLabelText="Complemento"
                      onChange={(e) =>
                        this.handleFormAddress('complement', e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <SelectFormId
                      name="state"
                      id="state"
                      validations={[required]}
                      elements={this.props.listStates}
                      label="Estado *"
                      dispatch={this.props.setSelectedState}
                      onChange={(e) => this.handleFormAddress('state', e)}
                      elements_selected={[this.props.state]}
                    />
                  </div>
                  <div className="col-md-4">
                    <SelectFormId
                      name="city"
                      id="city"
                      validations={[required]}
                      elements={this.props.listCities}
                      label="Cidade *"
                      dispatch={this.props.setSelectedCity}
                      onChange={(e) => this.handleFormAddress('city', e)}
                      elements_selected={[this.props.citySelected.id_sector]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <TextFieldForm
                      type="text"
                      name="neighborhood"
                      id="neighborhood"
                      floatingLabelText="Bairro"
                      onChange={(e) =>
                        this.handleFormAddress('neighborhood', e.target.value)
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {hasBrands && <UserTeamToggle />}
            <div className="row mt-5">
              <div className="col-md-12">
                <span className="label-tags">Tags e permissões</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 auto-complete-tags-user">
                <AutoCompleteForm
                  id="tags_user"
                  name="tags_user"
                  isLoading={this.props.isLoadingTags}
                  label="Tags *"
                  getTagAutoComplete={() => {}}
                  tags={this.props.listTagName}
                  infiniteScroll={{
                    searchByRequest: true,
                    loadMore: (page, search) => this.props.getTags(page, search)
                  }}
                />
              </div>
              <div className="col-md-4">
                <SelectForm
                  elements_selected={this.props.permission_selected}
                  name="permission_user"
                  validations={[required]}
                  multiple={false}
                  id="user_image"
                  elements={
                    this.state.user_roles === 'manager'
                      ? ['Usuário App']
                      : userTypes
                  }
                  label="Permissões *"
                  type="text"
                />
                {hasAcademyRole &&
                  this.props.permission_selected === 'Speaker' && (
                    <div className="mt-1">
                      <ToogleNoReduxForm
                        label="Pode visualizar cursos em rascunho"
                        value={academyRole}
                        onChange={() =>
                          this.setState({ academyRole: !academyRole })
                        }
                      />
                    </div>
                  )}
              </div>
            </div>
            {this.props.permission !== undefined ? (
              this.props.permission.includes('Speaker') ? (
                <div className="row-speaker-colum">
                  <div className="row">
                    <div className="col-md-4">
                      <TextFieldForm
                        name="score_user_speaker"
                        validations={[required, minValue0]}
                        id="nameuser"
                        floatingLabelText="Valor ao publicar *"
                        type="text"
                        isFormattedNumber
                      />
                    </div>
                    <div className="col-md-4">
                      <AutoCompleteForm
                        id="tags_user_speaker"
                        name="tags_user_speaker"
                        isLoading={this.props.isLoadingTags}
                        validations={[required]}
                        label="Tags permitidas*"
                        getTagAutoComplete={() => {}}
                        tags={this.props.listTagName}
                        infiniteScroll={{
                          searchByRequest: true,
                          loadMore: (page, search) =>
                            this.props.getTags(page, search)
                        }}
                      />
                    </div>
                  </div>
                  <TextFieldForm
                    name="publications_score"
                    validations={[required, minValue0]}
                    id="nameuser"
                    floatingLabelText="Valor da interação *"
                    type="number"
                  />
                </div>
              ) : (
                undefined
              )
            ) : (
              undefined
            )}
            {admin === 'true' ? (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <span className="label-tags">Atualizar Senha</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <TextFieldForm
                      name="password_user_new"
                      type="password"
                      validations={[minLength6]}
                      id="user_image"
                      floatingLabelText="Nova Senha"
                      autocomplete="new-password"
                    />
                  </div>
                </div>
                <div style={{ marginTop: '16px' }} className="row">
                  <ButtonDefault
                    label={'Redefinir Senha'}
                    onClick={handleSubmit((values) =>
                      this.props.newPassWord({ ...values, pill: 'blue' })
                    )}
                  />
                </div>
              </div>
            ) : (
              undefined
            )}
          </Body>
          <DialogAlert
            closeDialog={this.props.closeDialog}
            visible={this.props.alert.visible}
            message={this.props.alert.message}
          />
        </MuiThemeProvider>
      );
    } else {
      browserHistory.push('/user/list');
      return null;
    }
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'edituser'
})(EditUser);

const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (values, contacts, academyRole) => {
      dispatch(UserApi.patchUser(values, contacts, academyRole));
    },
    newPassWord: (values) => {
      dispatch(UserApi.rePassword(values));
    },
    closeDialog: () =>
      dispatch({ type: 'CLOSE_ERRO_ALERT', alert: { visible: false } }),
    getSectorUser: () => {
      dispatch(SectorApi.getSectorUser());
    },
    getTags: async (page, search) => {
      const res = await dispatch(TagApi.getTagsUserV2(page, search));
      return res;
    },
    getStates: () => {
      dispatch(UserApi.getStates());
    },
    getCities: (idState) => {
      dispatch(UserApi.getCities(idState));
    },
    setSelectedState: (element) => {
      dispatch({ type: 'UPDATE_STATE', state: element });
    },
    setSelectedCity: (element) => {
      dispatch({ type: 'UPDATE_CITY', city: element });
    },
    setAllowRequestCity: (allowRequestCity) => {
      dispatch({ type: 'ALLOW_REQUEST_CITY', allowRequestCity });
    },
    dispatchError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: 'Preencha a cidade'
        }
      });
    },
    dispatchImageError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: 'Não foi possível carregar a imagem',
          submessage:
            'O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.'
        }
      });
    }
  };
};
const selector = formValueSelector('edituser');

InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.user.userEdit,
    userEdit: state.user.userEdit,
    alert: state.erroalert,
    permission: selector(state, 'permission_user'),
    gender: selector(state, 'sex_user'),
    contact_email: selector(state, 'contact_email'),
    contact_phone: selector(state, 'contact_phone'),
    admitted_at: selector(state, 'admitted_at'),
    isLoadingTags: state.isLoadingtags,
    tags_selected: selector(state, 'tags_user'),
    permission_selected: selector(state, 'permission_user'),
    tags_user_speaker_selected: selector(state, 'tags_user_speaker'),
    tags: state.tagsparsed,
    sectorReducer: state.sectorReducer.sectorList,
    street:
      state.user.userEdit.address !== null &&
      state.user.userEdit.street !== undefined &&
      state.user.userEdit.street,
    city:
      state.user.userEdit.city !== null &&
      state.user.userEdit.city !== undefined &&
      state.user.userEdit.city.id,
    state:
      state.user.userEdit.state !== null &&
      state.user.userEdit.state !== undefined &&
      state.user.userEdit.state.id,
    neighborhood:
      state.user.userEdit.address !== null &&
      state.user.userEdit !== undefined &&
      state.user.userEdit.neighborhood,
    cityName:
      state.user.userEdit.city !== null &&
      state.user.userEdit.city !== undefined &&
      state.user.userEdit.city.name,
    zip_code:
      state.user.userEdit.address !== null &&
      state.user.userEdit.zip_code !== undefined &&
      state.user.userEdit.zip_code,
    listStates: state.user.listStates,
    listCities: state.user.listCities,
    stateSelected: state.user.stateSelected,
    citySelected: state.user.citySelected,
    allowRequestCity: state.user.allowRequestCity
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
