import API from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import PubSub from 'pubsub-js';
import errorHandler from '../componentes/utils/errorHandler';
import { browserHistory } from 'react-router';
import { style } from '../style';
export default class GalleryApi {
  static getGalleries(page) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      API.get(
        `galleries?page=${page}&per_page=9&source=admin`,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GET_LIST_GALLERIES',
              galleries: response.data
            });

            browserHistory.push('/galleries');

            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static getMoreGalleries = (page) => {
    return (dispatch) => {
      dispatch({ type: 'LOADING_GALLERIES_START' });

      API.get(
        `galleries?page=${page}&per_page=9&source=admin`,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GET_MORE_GALLERIES',
              galleries: response.data
            });

            browserHistory.push('/galleries');

            dispatch({ type: 'LOADING_GALLERIES_FINISH' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'LOADING_GALLERIES_FINISH' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  };

  static refreshGallery(galleryId) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      API.get(`galleries/${galleryId}/gallery_contents/`, Config.headers())
        .then(
          function(response) {
            dispatch({
              type: 'GALLERY_SELECT',
              content: response.data,
              galleryId
            });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static showGallery(galleryId) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      API.get(
        `galleries/${galleryId}/gallery_contents/?page=1`,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GALLERY_SELECT',
              content: response.data,
              galleryId
            });

            browserHistory.push('/gallery');

            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static getMoreGalleryPhotos = (galleryId, page) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data } = await API.get(
          `galleries/${galleryId}/gallery_contents/?page=${page}`,
          Config.headers()
        );

        dispatch({
          type: 'GET_MORE_PHOTOS',
          content: data,
          galleryId
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      }
    };
  };

  static sendFileGallery(file, id, kind, send_notification) {
    return (dispatch) => {
      const CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };

      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      const formdata = new FormData();
      formdata.append('name', file.name);
      formdata.append('kind', kind);
      formdata.append(kind, file);
      if (send_notification) {
        formdata.append('send_notification', send_notification);
      }

      API.post(`galleries/${id}/gallery_contents/`, formdata, CONFI)
        .then(
          function(response) {
            dispatch({
              type: 'SHOW_DIALOG_GALLERY',
              visible: false
            });
            dispatch({
              type: 'END_LOAD'
            });

            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });

            dispatch(this.showGallery(id));
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'END_LOAD'
            });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static sendFileGalleryVideo(file, id) {
    return (dispatch) => {
      const CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };

      const formdata = new FormData();
      formdata.append('video', file[0]);
      API.post(`galleries/${id}/gallery_videos`, formdata, CONFI)
        .then(
          function(response) {
            dispatch({
              type: 'SHOW_DIALOG_GALLERY',
              visible: false
            });
            dispatch({
              type: 'END_LOAD'
            });
            dispatch(this.showGallery(id));
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static deleteFileGallery(idgallery, idfile) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      const CONFI = Config.headers();

      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(
        `galleries/${idgallery}/gallery_contents/${idfile}`,
        CONFI
      )
        .then(
          function() {
            dispatch(this.refreshGallery(idgallery));
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static saveGallery(galleryName, tags) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      const form = new FormData();
      form.append('name', galleryName);
      tags.forEach((tag) => {
        form.append('tags[]', tag.value);
      });
      API.post(`galleries/`, form, Config.headers())
        .then(
          function(response) {
            const { data: newGallery } = response;
            newGallery.cover = null;

            dispatch({
              type: 'ADD_GALLERY',
              newGallery
            });
            PubSub.publish('close_dialog_gallery');

            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static editGallery = (galleryId, galleryName, tags) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const form = new FormData();
        form.append('name', galleryName);
        tags.forEach((tag) => {
          form.append('tags[]', tag.value);
        });

        await API.patch(`galleries/${galleryId}`, form, Config.headers()).then(
          (response) => {
            const { data: editGallery } = response;

            dispatch({
              type: 'EDIT_GALLERY',
              editGallery,
              galleryId
            });

            dispatch(this.getGalleries(1));
          }
        );

        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static destroyGallery(galleryId) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(`galleries/${galleryId}`, Config.headers())
        .then(
          function(response) {
            dispatch({
              type: 'DELETE_GALLERY',
              galleryId
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }
}
