import API, { API_V2 } from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import moment from 'moment-timezone';
import { browserHistory } from 'react-router';
import errorHandler from '../componentes/utils/errorHandler';
import { style } from '../style';

export default class RepositorieApi {
  static getFolders(page = 1, per_page = 10) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get(
        `repositories/?page=${page}&per_page=${per_page}&origin=web`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'GET_FOLDERS',
            folders: response.data
          });
          dispatch({ type: 'GET_PAGE', page });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error)
            }
          });
        });
    };
  }

  static getFiles(id) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_PROGRESS_INDETERMINATE'
      });
      API.get('repositories/' + id, Config.headers())
        .then((response) => {
          browserHistory.push('/files/list');
          dispatch({
            type: 'GET_FOLDERS',
            files: {
              folder_id: response.data.id,
              folder_name: response.data.name_folder,
              parent_id: response.data.previous_repository.id,
              files: response.data.repository_attachment,
              subfolders: response.data.repositories
            }
          });
          dispatch({
            type: 'HIDE_PROGRESS_INDETERMINATE'
          });
        })
        .catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error)
            }
          });
        });
    };
  }

  static getEditFiles(id, idfile) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_PROGRESS_INDETERMINATE'
      });
      API.get('repositories/' + id, Config.headers())
        .then((response) => {
          let file = this.parserFileForEdit(response.data, idfile);
          browserHistory.push('/file/edit');
          dispatch({
            type: 'EDIT_FILE',
            file: {
              folder_id: response.data.id,
              file
            }
          });
          dispatch({
            type: 'HIDE_PROGRESS_INDETERMINATE'
          });
        })
        .catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error)
            }
          });
        });
    };
  }

  static editFolder(id) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_PROGRESS_INDETERMINATE'
      });
      API.get('repositories/' + id, Config.headers())
        .then((response) => {
          let folder = this.parsefolder(response.data);
          browserHistory.push('/folder/edit');
          dispatch({
            type: 'EDIT_FOLDER',
            folder
          });
          dispatch({
            type: 'HIDE_PROGRESS_INDETERMINATE'
          });
        })
        .catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error)
            }
          });
        });
    };
  }

  static deleteFolder(id, is_subfolder, parent_id) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(
        'repositories/' + id + '?delete_folder=true',
        Config.headers()
      )
        .then((response) => {
          if (is_subfolder) {
            dispatch(this.getFiles(parent_id));
          } else {
            dispatch(this.getFolders(1));
          }
          dispatch({
            type: 'CLOSE_ALERT',
            alert: {
              visible: false
            }
          });
        })
        .catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error)
            }
          });
        });
    };
  }

  static patchFolder(values) {
    return (dispatch) => {
      let folder = this.buildFormData(values);
      API.patch('repositories/' + values.id, folder, Config.headers())
        .then((response) => {
          if (values.parent_id) {
            dispatch(this.getFiles(values.parent_id));
          } else {
            dispatch(this.getFolders());
            browserHistory.push('/files/folders');
          }
        })
        .catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error)
            }
          });
        });
    };
  }

  static saveFolder(values) {
    return (dispatch) => {
      let folder = this.buildFormData(values);
      API_V2.post('repositories/', folder, Config.headers())
        .then((response) => {
          if (values.parent_id) {
            dispatch(this.getFiles(values.parent_id));
          } else {
            dispatch(this.getFolders(1));
            browserHistory.push('/files/folders');
          }
          dispatch({
            type: 'NO_SUBMITTING'
          });
        })
        .catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error)
            }
          });
        });
    };
  }

  static parseObject(folders) {
    let folders_parser = [];

    folders.map((vl) => {
      let folder = {};
      folder.create_at = moment
        .tz(vl.create_at, 'Europe/Berlin')
        .format('DD/MM/YYYY');
      folder.id = vl.id;
      folder.name_folder = vl.name_folder;
      folder.tags_folder = [];
      if (vl.tags.length > 0) {
        folder.tags_folder = vl.tags[0].name;
      }
      folder.user_name = vl.user_id.name;
      folder.user_image = vl.user_id.image.url;
      folders_parser.push(folder);
    });

    return folders_parser;
  }

  static parserFileForEdit(files, idfiles) {
    let file_ = null;
    if (Array.isArray(files.repository_attachment)) {
      files.repository_attachment.map((file, i) => {
        if (file.id === idfiles) {
          file_ = file;
        }
      });
    }
    return file_;
  }

  static parsefolder(folders) {
    let folder = {};
    folder.create_at = moment
      .tz(folders.create_at, 'Europe/Berlin')
      .format('DD/MM/YYYY');
    folder.id = folders.id;
    folder.name_folder = folders.name_folder;
    folder.tags_folder = [];
    if (folders.tags.length > 0) {
      folders.tags.map(function(tag, t) {
        folder.tags_folder.push({
          value: tag.name,
          label: tag.name
        });
      });
    }
    folder.user_name = folders.user.name;
    folder.user_id = folders.user.id;
    folder.user_image = folders.user.image.url;
    folder.parent_id = folders.previous_repository.id;

    return folder;
  }

  static buildFormData(values) {
    let formData = new FormData();
    formData.append('name_folder', values.name_folder);
    formData.append('user_id', values.user_id);
    values.tags_folder.map((tag) => {
      formData.append('tags[]', tag.value);
    });

    if (values.parent_id) formData.append('parent_id', values.parent_id);

    return formData;
  }

  static buildFormDataFile(values, idfile) {
    let formData = new FormData();
    formData.append(
      'repository_attachment_attributes[0][name_file]',
      values.name_file
    );
    if (values.file[0] !== undefined)
      formData.append(
        'repository_attachment_attributes[0][file]',
        values.file[0]
      );
    if (values.url_file !== undefined) {
      formData.append(
        '[repository_attachment_attributes][0][link]',
        values.url_file
      );
    }
    formData.append('[repository_attachment_attributes][0][id]', idfile);

    return formData;
  }

  static saveFile(values, files, folder, sendType, notify_user = false) {
    return (dispatch) => {
      let formData = new FormData();
      formData.append('notify_user', notify_user);
      if (sendType === 'Enviar arquivos') {
        if (files !== undefined) {
          files.map(function(value, i) {
            formData.append(
              `repository_attachment_attributes[${i}][name_file]`,
              value.name
            );
            formData.append(
              `repository_attachment_attributes[${i}][file]`,
              value
            );
          });
        }
      } else {
        if (values.url_file === undefined) {
          values.url_file = '';
        } else {
          formData.append(
            `repository_attachment_attributes[0][name_file]`,
            values.name_file
          );
          formData.append(
            'repository_attachment_attributes[0][link]',
            values.url_file
          );
        }
      }
      let CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };

      API.patch('repositories/' + folder, formData, CONFI)
        .then((response) => {
          if (
            response.data.files_not_permited &&
            response.data.files_not_permited.length > 0
          ) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: 'Não foi possível carregar algum arquivo',
                fileList: response.data.files_not_permited,
                okFunction: () => {
                  dispatch(this.getFiles(folder));
                  browserHistory.push('/files/list');
                }
              }
            });
          } else {
            dispatch(this.getFiles(folder));

            browserHistory.push('/files/list');
          }
          dispatch({
            type: 'NO_SUBMITTING'
          });
          dispatch({ type: 'END_LOAD' });
        })
        .catch((error) => {
          dispatch({ type: 'END_LOAD' });
          if (error.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error)
            }
          });
        });
    };
  }

  static patchFile(values, folder, idfile) {
    return (dispatch) => {
      let formdata = this.buildFormDataFile(values, idfile);
      let CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };
      API.patch('repositories/' + folder, formdata, CONFI)
        .then((response) => {
          if (
            response.data.files_not_permited &&
            response.data.files_not_permited.length > 0
          ) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: 'Não foi possível carregar o arquivo',
                fileList: response.data.files_not_permited,
                okFunction: () => {
                  dispatch(this.getFolders(1));
                  browserHistory.push('/files/folders');
                }
              }
            });
          } else {
            dispatch(this.getFolders(1));

            browserHistory.push('/files/folders');
          }
          dispatch({ type: 'NO_SUBMITTING' });
          dispatch({ type: 'END_LOAD' });
        })
        .catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error)
            }
          });
        });
    };
  }

  static deleteFile(folderId, file) {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
        const formData = new FormData();
        formData.append('id_file', file);
        formData.append('delete_folder', false);
        let API_CLIENT = API;

        if (style.teamName.name === 'youseed') {
          API_CLIENT = APIDelete;
        }

        await API_CLIENT.delete(
          `repositories/${folderId}?id_file=${file}`,
          Config.headers()
        );
        dispatch({
          type: 'CLOSE_ALERT',
          alert: {
            visible: false
          }
        });
        dispatch({ type: 'NO_SUBMITTING' });

        const { data } = await API.get(
          `repositories/${folderId}`,
          Config.headers()
        );
        dispatch({
          type: 'GET_FOLDERS',
          files: {
            folder_id: data.id,
            files: data.repository_attachment,
            folder_name: data.name_folder,
            parent_id: data.previous_repository.id,
            subfolders: data.repositories
          }
        });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({
          type: 'CLOSE_ALERT',
          alert: {
            visible: false
          }
        });
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static getHeaderFileSend(value) {
    return (dispatch) => {
      dispatch({
        type: 'GET_HEADER_FILE_SEND',
        nameFormSend: value
      });
    };
  }
}
